
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default {
  name: "CommunicationsTE",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
    contactData: {
      type: Number,
      default: undefined,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    is_contact_entity: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "fieldSelected"],

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const contactTable = computed(() => store.getters.Contacts);
    const communicationsTable = computed(() => store.getters.Medias);
    const contactId = computed(() => store.getters.ContactSelected);
    const showContact = computed(() => props.contactData);

    const headers = ref([
      { label: "imedia", prop: "media_id" },
      { label: "ivalue", prop: "value" },
      { label: "Remarks", prop: "remark" },
    ]);
    const headerTable = computed(() => headers.value);

    const getData = () => {
      //
    };
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const elementsMedia = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });
    onMounted(() => {
      getData();
      if (showContact.value && props.is_contact_entity) {
        headers.value.unshift({ label: "icontact", prop: "keyContact" });
      }
      ApiService.get("/api/media/lists?per_page=1000").then(function (response) {
        elements.value.options = response.data.media;
      });
    });

    return {
      elements,
      elementsMedia,
      contactTable,
      loading,
      headers,
      headerTable,
      contactSelected,
      communicationsTable,
      contactId,
      showContact,
    };
  },
};
