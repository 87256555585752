<template>
  <div id="kt_profile_details_view" class="card mb-5 mb-xl-10">
    <div class="card-header cursor-pointer d-flex align-items-center">
      <div class="card-title m-0">
        <h3 class="m-0">{{ $t("details") }}</h3>
      </div>
      <div>
        <button class="custom-btn" @click="saveContact" v-if="canUpdate">
          {{ $t("btnSave") }}
        </button>
      </div>
    </div>
    <div class="card-body p-9">
      <div class="row mb-5">
        <div class="col-lg-3" style="margin-bottom: 10px">
          <label class="required">{{ $t("iname") }}</label>
          <div v-if="editMode === false" class="fw-bolder fs-6 text-dark">
            {{ contactData.name }}
          </div>
          <el-input
            v-else
            v-model="contactData.name"
            class="w-80"
            :placeholder="$t('iname')"
          />
        </div>
        <div class="col-lg-3 mt-3" style="margin-bottom: 10px">
          <label>{{ $t("ilast_name") }}</label>
          <div v-if="editMode === false" class="fw-bolder fs-6 text-dark">
            {{ contactData.last_name }}
          </div>
          <el-input
            v-else
            v-model="contactData.last_name"
            class="w-80"
            :placeholder="$t('ilast_name')"
          />
        </div>
        <div class="col-lg-3 mt-3" style="margin-bottom: 10px">
          <label>{{ $t("iidentification") }}</label>
          <div v-if="editMode === false" class="fw-bolder fs-6 text-dark">
            {{ contactData.identification }}
          </div>
          <el-input
            v-else
            v-model="contactData.identification"
            class="w-80"
            :placeholder="$t('iidentification')"
          />
        </div>
        <div class="col-lg-3" style="margin-bottom: 10px">
          <label class="required">{{ $t("contacts_type") }}</label>
          <div v-if="editMode === false" class="fw-bolder fs-6 text-dark">
            {{ contactData.last_name }}
          </div>
          <el-select
            v-model="contactData.contact_type_id"
            class="w-100"
            :placeholder="`${$t('contacts_type')}`"
            filterable
            remote
            reserve-keyword
          >
            <el-option
              v-for="element in contactType"
              :key="element.id"
              :label="element.name"
              :value="element.id"
            >
              <span>{{ `${element.id} - ${element.name}` }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-12" style="margin-bottom: 10px">
          <hr />
        </div>
        <div class="col-lg-6" style="margin-bottom: 10px">
          <CommunicationsTE
            v-if="contactData"
            :contact-data="1"
            :table-data="contactData.communications"
            @createElement="createCommunication"
            @removeElement="removeCommunication"
            :disable="!canUpdate"
          />
        </div>
        <div class="col-lg-6" style="margin-bottom: 10px">
          <EntitiesTE
            v-if="contactEntities"
            :table-data="contactEntities"
            @createElement="createEntity"
            @removeElement="removeEntity"
            :disable="!canUpdate"
          />
        </div>
      </div>
      <div class="row mb-5"></div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { computed } from "vue";
import store from "@/store";
import ApiService from "@/core/services/ApiService";
import CommunicationsTE from "@/components/entities/tables-editable/CommunicationsTE";
import { useRoute, useRouter } from "vue-router";
import EntitiesTE from "@/components/entities/tables-editable/EntitiesTE";

export default {
  name: "ContactGeneral",
  components: { EntitiesTE, CommunicationsTE },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const editMode = ref(true);
    const contactData = computed(() => store.getters.ContactData);
    const contactCommunication = computed(() => store.getters.Medias);
    const contactEntities = computed(() => store.getters.EntitiesData);
    const contactType = ref([]);
    const loadContactType = () => {
      store.commit("setLoadingStatus", true);
      ApiService.get("/api/contact-types").then(function (response) {
        contactType.value = response.data;
        store.commit("setLoadingStatus", false);
      });
    };
    const createEntity = () => {
      store.commit("createEntities", {});
    };
    const removeEntity = (i) => {
      contactEntities.value.splice(i, 1);
    };
    const createCommunication = () => {
      store.commit("createMedias", {
        media_id: "",
        value: "",
        remark: "",
      });
    };
    const removeCommunication = (i) => {
      contactCommunication.value.splice(i, 1);
    };
    const createCommunicationList = () => {
      store.commit("setLoadingStatus", true);
      ApiService.get("/api/contacts/" + route.params.id).then(function (
        response
      ) {
        store.commit("setMedias", response.data.communications);
        store.commit("setLoadingStatus", false);
      });
    };
    const saveContact = () => {
      store.commit("setLoadingStatus", true);
      const cEntities = [];
      const contactEntitiesNew = [];
      contactEntities.value.forEach(function (value) {
        if (value.id !== 0) {
          cEntities.push(value.id);
          contactEntitiesNew.push(value);
        }
      });
      store.commit("setEntities", contactEntitiesNew);

      const contactCommunicationNew = [];
      contactCommunication.value.forEach(function (value) {
        if (value.media_id && value.value) {
          contactCommunicationNew.push(value);
        }
      });
      store.commit("setMedias", contactCommunicationNew);

      const data = {
        name: contactData.value.name,
        last_name: contactData.value.last_name,
        born_date: contactData.value.born_date,
        identification: contactData.value.identification,
        contact_type_id: contactData.value.contact_type_id,
        entities: cEntities,
        communications: contactCommunication.value,
      };
      if (route.params.id !== "add") {
        ApiService.put("/api/contacts/" + route.params.id, data).then(function (
          response
        ) {
          store.commit("setLoadingStatus", false);
        });
      } else {
        ApiService.post("/api/contacts", data).then(function (response) {
          router.push({ path: "/contact/" + response.data.id + "/general" });
          store.commit("setLoadingStatus", false);
        });
      }
    };
    onMounted(() => {
      // console.log("ContatcGeneral onMounted route.params.id",route.params.id);
      if (route.params.id !== "add") {
        createCommunicationList();
      } else {
        store.commit("setMedias", []);
      }
      loadContactType();
    });

    const permissionsTemp = localStorage.getItem("permissions");
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canUpdate = computed(() => {
      return (
          permissions.value.findIndex((x) => x.name === "contacts type: update") !==
          -1
      );
    });

    return {
      editMode,
      contactData,
      contactType,
      saveContact,
      createCommunicationList,
      createCommunication,
      removeCommunication,
      createEntity,
      removeEntity,
      contactEntities,
      contactCommunication,
      canUpdate,
    };
  },
};
</script>

<style scoped></style>
