
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default {
  name: "EntitiesTE",
  components: {
    draggable: VueDraggableNext,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "fieldSelected"],

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const tableDataAux = ref(props.tableData);
    const contactTable = computed(() => store.getters.ContactData);
    const communicationsTable = computed(() => store.getters.EntitiesData);

    const headers = ref([
      { label: "iname", prop: "name" },
      { label: "ilegalname", prop: "legal_name" },
      { label: "iremark", prop: "identification" },
    ]);
    const headerTable = ref(headers.value);

    const selectElements = (query) => {
      ApiService.query(`/api/entities/lists`, {
        params: { per_page: 25, name: query },
      }).then(({ data }) => {
        elements.value.options = data;
      });
    };
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const elementsMedia = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });
    onMounted(() => {
      selectElements("");
    });

    watch(
      () => communicationsTable.value,
      (first) => {
        first.forEach((item) => {
          elements.value.options.entities.push(item);
        });
      }
    );

    return {
      elements,
      elementsMedia,
      contactTable,
      loading,
      headers,
      headerTable,
      contactSelected,
      communicationsTable,
      selectElements,
    };
  },
};
